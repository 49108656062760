.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.main-container {
  box-shadow: 0 -2px 4px 0 #d8d8d8;
  padding-bottom: 45px;
}
@media (min-width: 992px) {
.main-container {
    box-shadow: none;
    padding-top: 42px;
    padding-bottom: 69px;
}
}
@media (max-width: 991.98px) {
.page-home .main-container {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-top: 0;
}
}